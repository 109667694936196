import bulkActionTemplate from "./line-item-bulk-action.html";
import _ from "lodash";
import moment from "moment";
import {LINE_ITEM_OBJECTIVE_TYPE} from "../../components/api/resources/resources-constants";
import angular from "angular";
import {DEFAULT_MARKETPLACE_OBJECTIVE_FIELDS} from "../../components/session/local-network-profile";
import {LocalNetworkInfo} from "../../components/session/local-network-info";
import {ALL_TARGETING_TYPES} from "../targeting/targeting-constants";

const maxValues = {
  "AUD": 200,
  "EUR": 200,
  "THB": 4000,
  "NOK": 1500,
  "USD": 200,
  "SEK": 1500,
  "DKK": 1000,
  "SGD": 200,
  "IDR": 2000000,
  "VND": 3000000
};

export const productCreativeTag = 'tct918cp25c93wpm';
export const officialProductSimple = 'pzlwjm3nmddkr3c3';
export const officialHighImpactSimple = '3m0xt7zh7bmmjwl7';
export const simpleMarketplaceProducts = [officialProductSimple, officialHighImpactSimple, productCreativeTag];

export function filterOutUnwantedTargets(ctrl, targetType) {
  const info = _.get(ctrl, ['model', 'targeting', targetType.targets, targetType.apiProp]);
  if (_.get(info, ['length']) > 0) {
    _.set(ctrl, ['model', 'targeting', targetType.targets, targetType.apiProp], _.filter(info, function(singularInfo) {
      return singularInfo.url;
    }));
  }
}

export function isBidTooHigh(currentValue, originalValue) {
  const originalMaxValue = maxValues[_.get(originalValue, ['currency'], 'NOTHING')];
  if (originalMaxValue && originalValue.amount >= originalMaxValue) {
    return false;
  }
  const maxValue = maxValues[_.get(currentValue, ['currency'], "NOTHING")];
  return (maxValue && currentValue.amount >= maxValue) ? {amount: maxValue, currency: currentValue.currency} : false;
}

export const lineItemBulkAction = function(ctrl, $resources, resources, localResources) {

  const $uibModal = $resources.$uibModal,
    $filter = $resources.$filter,
    $translate = $resources.$translate;

  const LocalUserPermissions = localResources.LocalUserPermissions,
    LocalNetworkProfile = localResources.LocalNetworkProfile,
    adnTimeShifter = localResources.adnTimeShifter;

  return function() {
    const modalInstance = $uibModal.open({
      template: bulkActionTemplate,
      controllerAs: 'modalCtrl',
      size: 'lg',
      controller: /*@ngInject*/ function() {
        const modalCtrl = this,
          commons = lineItemCommons(modalCtrl, $translate, $filter, LocalNetworkProfile, LocalUserPermissions, adnTimeShifter, 'item');

        const firstSelectionUrl = _.get(_.find(ctrl.bulkSelection || [], function() {
          return true;
        }) || {}, 'url') || "";
        modalCtrl.isLineItemTemplate = firstSelectionUrl.indexOf("lineitemtemplate") > -1;
        modalCtrl.item = {};
        modalCtrl.form = {};
        modalCtrl.editing = {};
        modalCtrl.item.name = "{currentName}";
        modalCtrl.item.objectives = {};
        modalCtrl.item.bidSpecification = {};
        modalCtrl.item.rateLimits = [];
        modalCtrl.count = _.keys(ctrl.bulkSelection).length;
        modalCtrl.item.labelType = 'REPLACE';
        modalCtrl.item.exclusionLabelType = 'REPLACE';

        modalCtrl.tiers = angular.copy(LocalNetworkProfile.get('tiers'));

        const pureMarketplacePlatform = LocalNetworkProfile.isPureMarketplacePlatform();
        modalCtrl.allowedDeliveryCurves = ['FAST_AS_POSSIBLE', 'EVEN', 'CAPPED', 'FRONT_LOADED', 'CATCH_UP'];
        if (pureMarketplacePlatform) {
          modalCtrl.allowedDeliveryCurves = ['EVEN', 'CAPPED', 'FRONT_LOADED', 'CATCH_UP'];
        }

        commons.setSelfService();
        commons.setObjectivesAsString();
        commons.setObjectiveFields();
        commons.setBidSpecificationFields();
        commons.setAxAdvertiser();

        let newEndDate = true;
        modalCtrl.endDateCallback = function() {
          if (newEndDate) {
            const defaultEndDayStart = moment(modalCtrl.item.modEndDate).startOf('day').toDate();
            // this condition is necessary in case the time is adjusted first before the date, in which case we want to keep the time.
            if (moment(defaultEndDayStart).isSame(modalCtrl.item.modEndDate)) {
              modalCtrl.item.modEndDate = moment(modalCtrl.item.modEndDate).endOf('day').toDate();
            }
          }
          newEndDate = false;
        };

        modalCtrl.copy = function() {

          const lineItems = _.map(ctrl.bulkSelection, function(v, k) {
            const obj = {id: k};

            if (modalCtrl.editing.name) {
              obj.name = modalCtrl.item.name.replace("{currentName}", v.name);
            }
            if (modalCtrl.editing.userState && modalCtrl.item.userState) {
              obj.userState = modalCtrl.item.userState;
            }
            commons.modDates(modalCtrl.item.modStartDate, modalCtrl.item.modEndDate);
            if (modalCtrl.editing.startDate) {
              obj.startDate = modalCtrl.item.startDate;
            }
            if (modalCtrl.editing.endDate) {
              obj.endDate = modalCtrl.item.endDate;
            }
            if (modalCtrl.editing.objectives) {
              obj.objectives = modalCtrl.item.objectives;
            }
            if (modalCtrl.editing.type) {
              obj.type = modalCtrl.item.type;
            }
            if (modalCtrl.editing.cpmBid) {
              obj.bidSpecification = obj.bidSpecification || {};
              obj.bidSpecification.cpm = modalCtrl.item.bidSpecification.cpm;
            }
            if (modalCtrl.editing.cpcBid) {
              obj.bidSpecification = obj.bidSpecification || {};
              obj.bidSpecification.cpc = modalCtrl.item.bidSpecification.cpc;
            }
            if (modalCtrl.editing.smoothed) {
              obj.deliveryCurve = modalCtrl.item.deliveryCurve;
            }
            if (modalCtrl.editing.shareOfVoice) {
              obj.sponsorshipPercentage = modalCtrl.item.sponsorshipPercentage;
            }
            if (modalCtrl.editing.rateLimits) {
              obj.rateLimits = modalCtrl.item.rateLimits;
            }
            if (modalCtrl.editing.companionAds) {
              obj.companionAds = modalCtrl.item.companionAds;
            }
            if (modalCtrl.editing.deduplicationLevel) {
              obj.deduplicationLevel = modalCtrl.item.deduplicationLevel;
            }
            if (modalCtrl.editing.exclusionLabels) {
              obj.exclusionLabels = modalCtrl.item.exclusionLabelType === 'APPEND' ? v.exclusionLabels.concat(modalCtrl.item.exclusionLabels || []) : modalCtrl.item.exclusionLabels || [];
            }
            if (modalCtrl.editing.labels) {
              obj.labels = modalCtrl.item.labelType === 'APPEND' ? v.labels.concat(modalCtrl.item.labels || []) : modalCtrl.item.labels || [];
            }
            if (modalCtrl.editing.tier && modalCtrl.item.tier) {
              obj.tier = modalCtrl.item.tier || null;
            }

            return obj;
          });

          ctrl.miscBulkAction(modalInstance, modalCtrl, lineItems, null, {ignoreProduct: LocalNetworkProfile.getDefaults()['defaultIgnoreMarketplaceTargeting']});
        };
      }
    });
  };
};

export function setLineItemDefaults(LocalNetworkProfile, ctrl, model) {
  const networkDefaults = LocalNetworkProfile.getDefaults();
  ctrl.showCpmFees = networkDefaults.showCpmFees;
  ctrl.showExternalReference = networkDefaults.showExternalReference;
  if (ctrl.isNew) {
    model.startDate = moment().toDate();
    model.endDate = moment.tz(LocalNetworkProfile.get('timeZone') || 'utc').add(networkDefaults.lineItemDaysApart, 'day').endOf('day').toDate();

    if (ctrl.isAxAdvertiser) {
      model.userState = networkDefaults.marketplaceLineItemUserState;
    } else {
      model.userState = ctrl.isSelfService ? "PROPOSED" : networkDefaults.lineItemUserState || 'APPROVED';
    }
    if (!model.userState) {
      delete model.userState;
    }
    model.deduplicationLevel = networkDefaults.lineItemDeduplicationLevel;
    if (!model.deduplicationLevel) {
      delete model.deduplicationLevel;
    }
    _.set(ctrl, ['data', 'hasStats'], true);
    if (!ctrl.isAxAdvertiser && _.isObject(networkDefaults.cpm)) {
      model.bidSpecification = model.bidSpecification || {};
      model.bidSpecification.cpm = angular.copy(networkDefaults.cpm);
    }
    if (_.isArray(networkDefaults.cpmFees)) {
      model.bidSpecification = model.bidSpecification || {};
      model.bidSpecification.cpmFees = angular.copy(networkDefaults.cpmFees);
    }
  }
}

export function lineItemModDates(adnTimeShifter, model, modStartDate, modEndDate) {
  model.startDate = _.isDate(modStartDate) ? adnTimeShifter.timeShift(modStartDate, true) : null;
  model.endDate = _.isDate(modEndDate) ? adnTimeShifter.timeShift(modEndDate, true) : null;
}

export const lineItemCommons = function(ctrl, $translate, $filter, LocalNetworkProfile, LocalUserPermissions, adnTimeShifter, pParam) {
  const param = pParam || 'model';

  ctrl.isSelfService = LocalUserPermissions.isSelfService();
  ctrl.isAxAdvertiser = LocalUserPermissions.isAxAdvertiser();
  ctrl.isAdOps = LocalUserPermissions.isAdOps();

  return {
    setUpTiers() {
      const enabledTiers = LocalNetworkProfile.get('tiers'),
        currentTier = ctrl[param].tier;

      ctrl.tiers = angular.copy(enabledTiers);

      if (currentTier) {
        const hasCurrent = !!_.find(ctrl.tiers, function(t) {
          return t.id === _.get(currentTier, 'id');
        });
        // add current tier to list if not in there already -- is probably disabled
        if (!hasCurrent) {
          ctrl.tiers.push(currentTier);
        }
      }

      _.forEach(ctrl.tiers, function(t) {
        t.displayName = t.name + (_.find(enabledTiers, {id: t.id}) ? '' : ' (disabled)');
      });

      if (!currentTier && ctrl.isNew && !ctrl.isSelfService) {
        ctrl[param].tier = _.find(ctrl.tiers, {id: LocalNetworkProfile.get('defaultTierId')});
      }
    },
    modDates: function(modStartDate, modEndDate) {
      lineItemModDates(adnTimeShifter, ctrl[param], modStartDate, modEndDate);
    },
    setSelfService: function() {
      ctrl.isSelfService = LocalUserPermissions.isSelfService();
    },
    setAxAdvertiser: function() {
      ctrl.isAxAdvertiser = LocalUserPermissions.isAxAdvertiser();
    },
    setObjectiveFields: function(product) {
      if (product && product.budgetSpecification === 'BUDGET_ONLY') {
        _.forEach(DEFAULT_MARKETPLACE_OBJECTIVE_FIELDS, function(val, key) {
          ctrl.objectiveFields[key] = false;
        });
        ctrl.objectiveFields.BUDGET = true;
      } else if (product && product.type === 'CREATIVE_TAG' && ctrl.isAxAdvertiser) {
        ctrl.objectiveFields = [];
      } else {
        ctrl.objectiveFields = (!ctrl.showEverything || ctrl.isAxAdvertiser) ? LocalNetworkProfile.getDefaults().marketplaceObjectiveFields : LocalNetworkProfile.getDefaults().objectiveFields;
      }
      if (product && product.objectiveSpecification === 'IMPRESSIONS' && !ctrl.objectiveFields.RENDERED_IMPRESSION) {
        ctrl.objectiveFields.RENDERED_IMPRESSION = true;
      }
      _.forEach(_.get(ctrl, ['model', 'objectives']) || [], function(val, key) {
        if (val > 0 || _.get(val, 'amount') > 0) {
          ctrl.objectiveFields[key] = true;
        }
      });
      const oFieldsArray = _.filter(_.map(ctrl.objectiveFields, function(o, key) {
        return o ? key : false;
      }), function(o) {
        return o;
      });
      ctrl.singleObjective = oFieldsArray.length === 1;
      ctrl.singleBudgetObjective = ctrl.singleObjective && oFieldsArray[0] === 'BUDGET';

      ctrl.rateLimitTypes = _.filter(_.map(LocalNetworkProfile.getDefaults().rateLimitFields, function(val, key) {
        return val ? key : false;
      }), function(val) {
        return val;
      });
    },
    setBidSpecificationFields: function() {
      function setFields(ctrlParam) {
        ctrl[ctrlParam] = LocalNetworkProfile.getDefaults()[ctrlParam];
        if (ctrl[param].type === 'CREATIVE_TAG') {
          ctrl[ctrlParam].cpc = false;
          ctrl[ctrlParam].cpa = false;
          ctrl[ctrlParam].cpm = false;
        }
        _.forEach(_.get(ctrl.model, 'bidSpecification') || [], function(val, key) {
          if (val > 0 || _.get(val, 'amount') > 0) {
            ctrl[ctrlParam][key] = true;
          }
        });
      }

      setFields('bidSpecificationFields');
      setFields('marketplaceBidSpecificationFields');
    },
    setObjectivesAsString: function() {
      const methodName = pParam ? pParam + "ObjectivesAsString" : "objectivesAsString";
      ctrl[methodName] = function() {
        let objectives = [];
        _.forEach(ctrl[param].objectives, function(o, key) {
          const objData = {
            number: o,
            key: key,
            translation: $filter('adnFieldTranslate')('lineItem.objective.' + key + '.plural').toLowerCase()
          };
          const amount = _.get(o, 'amount');
          if (amount) {
            const managedAmount = _.isString(amount) ? parseFloat(amount) : amount;
            objData.prettyNumber = $filter('isoCurrency')(managedAmount, _.get(o, 'currency'));
          } else if (_.isFinite(o) || (_.isString(o) && _.trim(o).length > 0)) {
            objData.prettyNumber = $filter('number')(o);
          }
          if (objData.prettyNumber) {
            objectives.push(objData);
          }
        });
        if (objectives.length === 0) {
          return "No objectives specified";
        }
        objectives = _.orderBy(objectives, function(d) {
          return LINE_ITEM_OBJECTIVE_TYPE[d.key].order;
        });
        let returnString = "";
        _.forEach(objectives, function(d, index) {
          returnString += d.prettyNumber + " " + d.translation;
          if (index + 1 < objectives.length) {
            returnString += " or ";
          }
        });
        return returnString;
      };
    }
  };
};

export const lineItemListCommons = function(ctrl, afterFill, LocalNetworkProfile, adnTimeShifter, $translate, $filter, LocalUserPermissions, Team, Order, view) {
  _.forEach(ctrl.items, function(li) {
    if (li.startDate) {
      li.modStartDate = adnTimeShifter.timeShift(li.startDate);
    }
    if (li.endDate) {
      li.modEndDate = adnTimeShifter.timeShift(li.endDate);
    }
  });

  const commons = lineItemCommons(ctrl, $translate, $filter, LocalNetworkProfile, LocalUserPermissions, adnTimeShifter);
  commons.setObjectiveFields();

  if (afterFill) {
    return;
  }

  ctrl.getOrders = function() {
    if (!ctrl.orders && ctrl.advertiserId) {
      ctrl.gettingOrders = true;
      Order.query({minimal: true, advertiser: ctrl.advertiserId}).$promise.then(function(page) {
        ctrl.orders = page.results;
        ctrl.gettingOrders = false;
      });
    }
  };
  ctrl.assignAdvertiser = function(advertiserId) {
    ctrl.advertiserId = advertiserId;
  };

  ctrl.teamsCount = LocalNetworkInfo.obtain().info.teamsCount;
  ctrl.getTeams = function() {
    if (!ctrl.teams && ctrl.teamsCount > 1 && ctrl.teamsCount <= 15) {
      ctrl.gettingTeams = true;
      Team.query({minimal: true}).$promise.then(function(page) {
        ctrl.teams = page.results;
        ctrl.gettingTeams = false;
      });
    }
  };
  ctrl.blockTeams = function() {
    ctrl.teamsCount = 0;
  };

  ctrl.countries = _.orderBy(_.map(LocalNetworkInfo.obtain().getTeamCountriesSet(), function(c) {
    return {
      id: c ? c : 'ZZ',
      expanded: c ? $filter('country')(c) : "unspecified"
    };
  }), ['id']);

  ctrl.targetingTypes = ALL_TARGETING_TYPES;
  ctrl.hasLineItemTemplatesCount = LocalNetworkInfo.obtain().getInfo('lineItemTemplatesCount') > 0;
  ctrl.hasObjectSource = LocalNetworkProfile.hasObjectSource();
  ctrl.allFields = ['name', 'userState', 'executionState'];
  if (view === 'LINE_ITEM_VIEW') {
    ctrl.allFields.push({label: 'advertiser', param: 'ReadOnlyAdvertiser'});
  }
  if (view !== 'ORDER_VIEW') {
    ctrl.allFields.push({label: 'order', param: 'Order'});
  }
  ctrl.allFields = ctrl.allFields.concat([{label: 'tier', param: 'Tier'}, {param: 'objectives', numeric: true}, {label: 'bids', param: '', numeric: true}, 'startDate', 'endDate', {label: 'startDate', param: 'startDate|withTime', otherLabel: 'startDateAndTime'}, {label: 'endDate', param: 'endDate|withTime', otherLabel: 'endDateAndTime'}, 'createUser', 'updateTime', {label: 'targeting', param: ''}]);
  if (ctrl.hasObjectSource) {
    ctrl.allFields.splice(1, 0, 'source');
  }
  if (!ctrl.perms.MANAGE_ADVERTISING) {
    ctrl.allFields = ['name', 'userState', 'executionState', {label: 'order', param: 'Order'}, 'objectives', {label: 'bids', param: '', numeric: true}, 'startDate', 'endDate', {label: 'startDate', param: 'startDate|withTime', otherLabel: 'startDateAndTime'}, {label: 'endDate', param: 'endDate|withTime', otherLabel: 'endDateAndTime'}, 'createUser', 'updateTime'];
  }
  ctrl.allFields.push({param: "spendDelivery", numeric: true});
  ctrl.allFields.push({labelInstant: LocalNetworkProfile.getNetworkTranslations('impression'), param: "impressionDelivery", numeric: true});
  ctrl.allFields.push({labelInstant: LocalNetworkProfile.getNetworkTranslations('rendered'), param: "renderedDelivery", numeric: true});
  ctrl.allFields.push({param: "viewableDelivery", numeric: true});
  ctrl.allFields.push({param: "clickDelivery", numeric: true});
  ctrl.allFields.push({param: "viewability", numeric: true});
  ctrl.allFields.push({param: "", label: "eCpm", numeric: true});
  ctrl.allFields.push({param: "", label: "cpc", numeric: true});
  ctrl.allFields.push({param: "ctr", numeric: true});
  ctrl.allFields.push({param: "deliveryPercentage"});
  ctrl.allFields.push({param: "elapsedTimePercentage"});
  ctrl.allFields.push({param: "deliveryPercentageYesterday", label: 'deliveryPercentageYesterdaySmall', otherLabel: 'deliveryPercentageYesterday'});
  ctrl.allFields.push({param: "deliveryPercentageToday", label: 'deliveryPercentageTodaySmall', otherLabel: 'deliveryPercentageToday'});
  ctrl.allFields.push({param: "velocity|progress", label: 'progress'});
  ctrl.allFields.push({param: "velocity", label: "progressSlim"});
  ctrl.allFields.push({param: 'velocity|progressFull', label: 'progressFull'});

  if (view === 'SLIM') {
    ctrl.allFields = ['name', 'userState', 'executionState', 'startDate', 'endDate'];
  }

  ctrl.view = view;
  const skipForDefaults = ctrl.perms.AX_ADVERTISER || ctrl.perms.AX_ADVERTISER_ADMIN ?
    ['source', 'advertiser', 'order', 'createUser', 'updateTime', 'bids', 'targeting', 'spendDelivery', 'impressionDelivery', 'clickDelivery', 'viewability', 'eCpm', 'cpc', 'viewableDelivery', 'ctr', 'startDateAndTime', 'endDateAndTime', 'deliveryPercentage', 'elapsedTimePercentage', 'deliveryPercentageYesterday', 'deliveryPercentageToday', 'velocity|progress', 'velocity|progressFull'] :
    ['source', 'advertiser', 'order', 'createUser', 'updateTime', 'bids', 'targeting', 'spendDelivery', 'impressionDelivery', 'renderedDelivery', 'viewability', 'eCpm', 'cpc', 'viewableDelivery', 'clickDelivery', 'ctr', 'startDateAndTime', 'endDateAndTime', 'deliveryPercentage', 'elapsedTimePercentage', 'deliveryPercentageYesterday', 'deliveryPercentageToday', 'velocity|progress', 'velocity|progressFull'];
  ctrl.defaultFields = _.filter(ctrl.allFields, function(f) {
    return skipForDefaults.indexOf(f.label || f.param || f) < 0;
  });
  ctrl.sel = {};
};